import React from 'react';
import { Link } from 'react-router-dom';
import authLogoImage from '../../assets/images/auth-logo.svg';
import authLogoRtlImage from '../../assets/images/auth-logo.svg';
import LogoImage from '../../assets/images/logo.svg';
import LogoRtlImage from '../../assets/images/auth-logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArabicLocale,
  EnglishLocale,
  ILocale,
  State,
  changeLanguageAction,
} from '../../core/store';

interface CustomProps {
  notLoggedIn?: boolean;
}

const Header = (props: CustomProps) => {
  const settings = useSelector((state: State) => state.settings);
  const dispatch = useDispatch();
  const onLanguageChange = (language: ILocale) => {
    dispatch(changeLanguageAction(language.languageID));
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
  return (
    <div
      className={`px-4 ${
        props.notLoggedIn
          ? ' xl:px-14 py-7 lg:py-10'
          : 'bg-primary xl:px-6 py-4 lg:py-1 text-white'
      }`}
    >
      <div className="flex items-center justify-between -mx-4 py-px">
        <div className="px-4">
          <Link to={`/`} className="flex w-[207px] lg:w-[262px]">
            {props.notLoggedIn ? (
              <>
                {settings?.locale?.isRtl ? (
                  <img
                    src={authLogoRtlImage}
                    alt="Logo"
                    className="max-w-full"
                  />
                ) : (
                  <img src={authLogoImage} alt="Logo" className="max-w-full" />
                )}
              </>
            ) : (
              <>
                {settings?.locale?.isRtl ? (
                  <img src={LogoRtlImage} alt="Logo" className="max-w-full" />
                ) : (
                  <img src={LogoImage} alt="Logo" className="max-w-full" />
                )}
              </>
            )}
          </Link>
        </div>
        <div className="px-4">
          <span
            className={`font-bold leading-none ltr:font-arabic cursor-pointer ${
              settings?.locale?.languageID === 1 ? '' : 'text-[13px]'
            }`}
            onClick={() => {
              if (settings.locale.languageID === 1) {
                onLanguageChange(new ArabicLocale());
              } else {
                onLanguageChange(new EnglishLocale());
              }
            }}
          >
            {settings?.locale?.languageID === 1 ? 'عربي' : 'English'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
