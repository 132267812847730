import { ILocale } from '../../store/reducers/settings';
import {
  ApiResponse,
  ICustomerBulkImportResponse, 
  NewOtpResponse, 
  Roles,
  SettingsData,
  TokenData,
  UserData,
} from '../models';
import { SignUpModel, ISignup, ChangePasswordProps } from '../models/signup';
import * as http from './http';
import ApiService from './service';

export class AuthService extends ApiService {
  static loginUrl = '/api/login/index/';
  static signUpUrl = '/storeirf/signup';
  static forgotUrl = '/api/user/forgotPassword/';
  static resetUrl = '/storeirf/reset';
  static createPass = '/api/login/resetPassword';
  static getSettings = '/api/index/commonSettings';

  public async login(credentials: { userLogin: string; userPassword?: string }) {
    const url = `${this.apiDomain}${AuthService.loginUrl}`;
    const data = new FormData();
    data.append('userLogin', credentials.userLogin);
    if (credentials?.userPassword) {
      data.append('userPassword', credentials.userPassword);
    }
    const response = await http.postAsFormData<ApiResponse<UserData>>(
      url,
      data
    );
    return response.data;
  }

  public async signup(data: ISignup) {
    const formData = new FormData();
    formData.append('companyName', data.companyName);
    formData.append('countryID', data.countryID);
    formData.append('userEmail', data.userEmail);
    formData.append('userPhone', data.userPhone);
    // formData.append('userFirstName', data.userFirstName);
    // formData.append('userLastName', data.userLastName);
    formData.append('brandID', '');
    formData.append('branchID', '');
    formData.append('tradeno', data?.tradelicensenumber ?? '');
    formData.append('trn', data?.TRNnumber ?? '');
    formData.append('vatNo', data.VATnumber);
    // formData.append('userCity', data.city);
    formData.append('stateID', data.stateID);
    formData.append('userDialcode', data.userDialcode);
    formData.append('brandBranch', JSON.stringify(data.brandBranch));
    const url = `${this.apiDomain}${AuthService.signUpUrl}`;
    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      formData
    );
    return response;
  }

  public async forgot(data: any) {
    const formData = new FormData();
    formData.append('emailID', data.emailID);
    const url = `${this.apiDomain}${AuthService.forgotUrl}`;
    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      formData
    );
    return response;
  }

  public async reset(data: any) {
    const formData = new FormData();
    formData.append('userEmail', data.userLogin);
    formData.append('code', data.otp);
    formData.append('userPassword', data.newPassword);

    const url = `${this.apiDomain}${AuthService.resetUrl}`;

    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      formData
    );
    return response;
  }

  public async verifyOtp(data: { otp: string; emailID: string; otpSection: string }): Promise<any> {
    const url = `${this.apiDomain}/api/login/verifyotp`;
    const formData = new FormData();
    formData.append('otp', data.otp);
    formData.append('emailID', data.emailID);
    formData.append('otpSection', data.otpSection);
    const response = await http.postAsFormData<ApiResponse<UserData>>(
      url,
      formData
    );
    return response;
  }

  public async newPassword(cred: {
    otp: string;
    emailID: string;
    newPassword: string;
  }): Promise<any> {
    const url = `${this.apiDomain}${AuthService.createPass}`;
    const data = new FormData();

    data.append('newPassWord', cred.newPassword);
    data.append('confirmPassword', cred.newPassword);
    data.append('otp', cred.otp);
    data.append('emailID', cred.emailID);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response;
  }

  public async changePassword(data: ChangePasswordProps) {
    const formData = new FormData();
    formData.append('newPassword', data.newPassword);
    formData.append('oldPassword', data.oldPassword);
    formData.append('confirmPassword', data.confirmPassword);
    formData.append('brandID', '');
    formData.append('branchID', '');
    const url = `${this.apiDomain}/user/changepassword/`;
    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      formData
    );
    return response;
  }

  // public async getRoles(): Promise<ApiResponse<Roles>> {
  //   const url = `${this.apiDomain}/user/checkRoles/`;
  //   const data = new FormData();
  //   const response = await http.postAsFormData<ApiResponse<any>>(
  //     url,
  //     data,
  //     true
  //   );
  //   return response?.data;
  // }
  
  public async getRoles(): Promise<any> {
    // const url = `${this.apiDomain}/user/checkRoles/`;
    // const data = new FormData();
    // const response = await http.postAsFormData<ApiResponse<any>>(
    //   url,
    //   data,
    //   true
    // );
    const response = {
      data: {
        roles: []
      }
    }
    return response;
  }

  public async bulkUploadPreviewCustomers(
    file: File
  ): Promise<ApiResponse<{ data: ICustomerBulkImportResponse[] }>> {
    const url = `${this.apiDomain}/salesperson/bulkUploadCustomer/`;
    const formData = new FormData();
    formData.append('bulkUserFile', file);
    const response = await http.postAsFormData(url, formData);

    return response?.data;
  }

  public async submitBulkCustomer(ids?: string): Promise<any> {
    const url = `${this.apiDomain}/salesperson/bulkUploadCustomerConfirmation/`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async resendOTP(emailID: string) {
    const formData = new FormData();
    formData.append('emailID', emailID);
    const url = `${this.apiDomain}/api/user/resendOTP`;
    const response = await http.postAsFormData<ApiResponse<NewOtpResponse>>(url, formData);
    return response?.data;
  }

  public async getSettings(): Promise<ApiResponse<{ settings: SettingsData, languages: ILocale[] }>> {
    const url = `${this.apiDomain}${AuthService.getSettings}`;
    const response = await http.get<ApiResponse<{ settings: SettingsData, languages: ILocale[] }>>(url);
    return response?.data;
  }
}
