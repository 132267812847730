import api from '../../../api';
import { ApiResponse, TokenData, UserData, UserType } from '../models';
import { IChecklist, IJobData, IJobDetails, IJobDetailsResponse, IReasonData } from '../models/home'; 
import * as http from './http';
import ApiService from './service';

export class DashboardApiService extends ApiService {
  static dashboardUrl = '/api/user/profileupdate/';
  static submitCoupon = '/storeirf/submitcoupon';
  static Coupon = '/storeirf/submitcoupon';
  static sendcouponOTP = '/storeirf/sendcouponOTP';
  static verifycouponOTP = '/storeirf/verifycouponOTP';
  static addinvoice = '/storeirf/addinvoice';
  static returnCoupon = '/storeirf/markreturn/';
  static privacyPolicyCms = '/page/index/pageKey/privacy-policy-irf';
  static getTermsUrl = '/page/index/pageKey/terms-and-conditions-irf';
  static getJobList = '/api/joblist/index/';
  static getJobDetails = '/api/joblist/details/id/';
  static changeJobStatus = '/api/joblist/startstop';
  static getCheckList = '/api/joblist/jobchecklist';
  static updateJobChecklist = '/api/joblist/qcsubmit';
  static getReasonData = '/api/joblist/jobreason';
  static reOpenJob = '/api/joblist/reopen';
  static changeWasingPerson = '/api/joblist/employee';

  public async dashboard() {
    const url = `${this.apiDomain}${DashboardApiService.dashboardUrl}`;
    const response = await http.get<ApiResponse<UserData>>(url);
    return response.data;
  }

  public async submitCode({ couponCode, userToken }) {
    const url = `${this.apiDomain}${DashboardApiService.submitCoupon}`;
    const data = new FormData();
    data.append('userToken', userToken);
    data.append('couponCode', couponCode);
    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      data
    );
    return response.data;
  }

  public async getCouponDetails({ couponId, userToken }) {
    const languageID = api.getLanguageID();
    const url = `${this.apiDomain}/storeirf/getcoupondetails/couponID/${couponId}/userToken/${userToken}/website_id/1/subsite_id/1/languageID/${languageID}/`;
    const response = await http.get(url);
    return response.data;
  }
  public async sendcouponOTP({ couponId, userToken }) {
    const url = `${this.apiDomain}${DashboardApiService?.sendcouponOTP}`;
    const data = new FormData();
    data.append('userToken', userToken);
    data.append('couponID', couponId);
    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      data
    );
    return response.data;
  }
  public async verifycouponOTP({ couponId, userToken, otp }) {
    const url = `${this.apiDomain}${DashboardApiService?.verifycouponOTP}`;
    const data = new FormData();
    data.append('userToken', userToken);
    data.append('couponID', couponId);
    data.append('otp', otp);
    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      data
    );
    return response.data;
  }
  public async getCouponDeatilsForPrint({ couponId, orderItemId }) {
    const languageID = api.getLanguageID();
    const url = `${this.apiDomain}/couponirf/getCustomerInfo/couponID/${couponId}/orderItemID/${orderItemId}/website_id/1/subsite_id/1/languageID/${languageID}`;
    const response = await http.get(url);
    return response.data;
  }
  public async addInvoices({ couponId, userToken, data }) {
    const url = `${this.apiDomain}${DashboardApiService?.addinvoice}`;
    const formData = new FormData();
    formData.append('userToken', userToken);
    formData.append('couponID', couponId);
    data?.wipNumber && formData.append('wipNumber', data?.wipNumber);
    if (data?.invoiceNumber) {
      formData.append('invoiceNumber', data?.invoiceNumber);
    }
    formData.append('orderTaxAmount', data?.orderTaxAmount);
    formData.append('orderSubTotal', data?.orderSubTotal);
    formData.append('orderTotalAmount', data?.orderTotalAmount);
    data?.validateField && formData.append('validateField', 'Yes');
    (data?.rebateUsedAmount || data?.rebateUsedAmount === 0) &&
      formData.append('rebateUsedAmount', data?.rebateUsedAmount);
    data?.cashAmount && formData.append('cashAmount', data?.cashAmount);
    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      formData
    );
    return response.data;
  }
  public async returnCoupon({ orderItemID, userToken }) {
    const url = `${this.apiDomain}${DashboardApiService?.returnCoupon}`;
    const formData = new FormData();
    formData.append('userToken', userToken);
    formData.append('orderItemID', orderItemID);
    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      formData
    );
    return response.data;
  }
  public async getPrivacyPolicy({  userToken }) {
    const url = `${this.apiDomain}${DashboardApiService?.privacyPolicyCms}`;
    const formData = new FormData();
    formData.append('userToken', userToken);
    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      formData
    );
    return response.data;
  }  public async getTerms({  userToken }) {
    const url = `${this.apiDomain}${DashboardApiService?.getTermsUrl}`;
    const formData = new FormData();
    formData.append('userToken', userToken);
    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      formData
    );
    return response.data;
  }
  getUserType() {
    return api.getUserType();
  }
  public async getJobList({ userToken, jobStatus, searchName, searchDate, page, count }) {
    const url = `${this.apiDomain}${DashboardApiService?.getJobList}?page=${page}&count=${count}`;
    const data = {
      jobStatus,
      searchName,
      searchDate
    };
    const response = await http.post<ApiResponse<IJobData>>(url, data);
    return response.data;
  }
  public async getJobDetails({ jobID }) {
    const url = `${this.apiDomain}${DashboardApiService?.getJobDetails}${jobID}`;
    const response = await http.get<ApiResponse<IJobDetailsResponse>>(url);
    return response.data;
  }
  public async changeJobStatus(payload) {
    const url = `${this.apiDomain}${DashboardApiService?.changeJobStatus}`;
    const response = await http.post<ApiResponse<TokenData>>(url, payload);
    return response.data;
  }
  public async getCheckList() {
    const url = `${this.apiDomain}${DashboardApiService?.getCheckList}`;
    const response = await http.get<ApiResponse<IChecklist>>(url);
    return response.data;
  }
  public async changeQcStatus(payload) {
    const url = `${this.apiDomain}${DashboardApiService?.changeJobStatus}`;
    const response = await http.post<ApiResponse<TokenData>>(url, payload);
    return response.data;
  }
  public async updateJobChecklist(payload) {
    const url = `${this.apiDomain}${DashboardApiService?.updateJobChecklist}`;
    const response = await http.post<ApiResponse<TokenData>>(url, payload);
    return response.data;
  }
  public async getReasonData(payload) {
    const url = `${this.apiDomain}${DashboardApiService?.getReasonData}`;
    const response = await http.post<ApiResponse<IReasonData>>(url, payload);
    return response.data;
  }
  public async reOpenJob(payload) {
    const url = `${this.apiDomain}${DashboardApiService?.reOpenJob}`;
    const response = await http.post<ApiResponse<TokenData>>(url, payload);
    return response.data;
  }
  public async changeWasingPerson(payload) {
    const url = `${this.apiDomain}${DashboardApiService?.changeWasingPerson}`;
    const response = await http.post<ApiResponse<any>>(url, payload);
    return response.data;
  }
}
