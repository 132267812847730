import React, { useEffect, useState } from 'react';
import Layout from '../../core/layouts/Layout';
import AuthCanvas from '../../components/auth/AuthCanvas';
import { useTranslation } from 'react-i18next';
import OTP from '../../components/otp';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../core/hooks';
import { FullPageLoader } from '../../components/loader';
import Timer from '../../components/timer/Timer';
import { toastr } from 'react-redux-toastr';
import { NewOtpResponse } from '../../core/api/models/user';
import { useSelector } from 'react-redux';
import { State } from '../../core/store';
import Header from '../../components/header';

const OtpConfirmation: React.SFC = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const location: any = useLocation();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [otpToken, setOtpToken] = useState<number | string>('');
  const [loading, setLoading] = useState(false);
  const settings = useSelector((state: State) => state.settings);
  const [timer, setTimer] = useState(0);
  const [otpkey, setOtpkey] = useState('1');



  useEffect(() => {
    if (location?.state?.data?.emailID) {
      setEmail(location?.state?.data?.emailID);
      setOtpkey(Math.random().toString());
    } else {
      history.push('/auth/forgot-password');
    }
  }, [location?.state]);

  useEffect(() => {
    if (settings.settings.otpExpireTime) {
      setTimer(settings.settings.otpExpireTime * 60);
    } else {
      history.push('/');
    }
  }, [settings]);
  useEffect(() => {
    // Custom message when user reloads the page
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      history.push('/auth/forgot-password');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const onSuccess = (response: any, data: any) => {
    if (response?.data?.meta?.status === 'success') {
      history.push('/auth/reset-password', {
        email,
        data,
      });
    }
  };

  const otpSubmit = (data: any) => {
    setLoading(true);
    try {
      const otp = `${data?.input1}${data.input2}${data?.input3}${data?.input4}${data?.input5}${data?.input6}`;
      if (otp.length < 6) return;
      setOtpToken(otp);
      auth.verifyOtp({ otp, emailId: email }, onSuccess);
    } catch (error) {
      toastr.error(t('common.error'), (error as any)?.response?.data?.meta?.message || t('common.SomethingWentWrong'));
    } finally {
      setLoading(false);
    }
  };

  const childRef: any = React.createRef();


  const resendOtp = async () => {
    setLoading(true);
    const res = await auth?.resendOtp(email);
    if (res?.otpExpireTime) {
      childRef?.current?.restartTimer();
      setOtpkey(Math.random().toString());
      setTimer(res?.otpExpireTime * 60);
      setLoading(false);
    } else {
      toastr.error(t('common.somethingwentwrong'), '');
    } 
  };
  return (
    <Layout className="bg-slate-50" notLoggedIn>
      {loading && <FullPageLoader text="Loading..." />}
      <Header notLoggedIn={true} />
      <div className="container pt-1.5 lg:pt-10 pb-14">
        <AuthCanvas
          head={t('common.VerifyOTP')}
          subText={t('common.VerifyOTPSubtext')}
        >
          <OTP valueOnSubmit={otpSubmit} />
          <div className="text-center text-sm font-medium">
            {otpkey && (
              <Timer
                  timerKey={otpkey}
                  beforeText={t('common.ResendOTPIn')}
                  time={timer}
                  ref={childRef}
              >
                <span
                  onClick={resendOtp}
                  className="text-primary text-underline cursor-pointer"
                >
                  {t('common.ResendOTP')}
                </span>
              </Timer>
            )}
          </div>
        </AuthCanvas>
      </div>
    </Layout>
  );
};

export default OtpConfirmation;
