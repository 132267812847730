import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticateRoute from './AuthenticateRoute';
import RedirectIfAuthenticatedRoute from './RedirectIfAuthenticatedRoute';
import { RouteKeys } from './route-keys';
import { UserPermissionTypes } from '../../core/api/models';
import { ContainerLoader } from '../../components/loader';
import Password from '../auth/password';
import OtpConfirmation from '../auth/otp-confirmation';

const Home = lazy(() => import('../home'));
const Login = lazy(() => import('../auth/login'));
const ForgotPassword = lazy(() => import('../auth/forgot-password'));
const SetNewPassword = lazy(() => import('../auth/reset-password'));
const PrivacyPolicy = lazy(() => import('../privacy/Privacy'));
const Terms = lazy(() => import('../terms/Terms'));
const JobList = lazy(() => import('../job-list/JobList'));
const QualityChecklist = lazy(() => import('../job-list/QualityChecklist'));
const QualityChecklistSummary = lazy(
  () => import('../job-list/QualityChecklistSummary')
);
const WCBoard = lazy(
  () => import('../board/WCBoard')
);

class Routes extends React.Component {
  render() {
    return (
      <Suspense fallback={<ContainerLoader height={500} text={'Loading...'} />}>
        <Switch>
          <AuthenticateRoute
            exact
            path={`${RouteKeys.Home}`}
            component={Home}
          />
          {/* <Route exact path={`${RouteKeys.Home}`} component={Home} /> */}
          <RedirectIfAuthenticatedRoute
            path={RouteKeys.Login}
            component={Login}
          />
          <RedirectIfAuthenticatedRoute
            path={RouteKeys.PasswordForm}
            component={Password}
          />
          <RedirectIfAuthenticatedRoute
            path={RouteKeys.OtpForNewPassword}
            component={OtpConfirmation}
          />
          <RedirectIfAuthenticatedRoute
            path={RouteKeys.ForgotPassword}
            component={ForgotPassword}
          />
          <RedirectIfAuthenticatedRoute
            path={RouteKeys.SetNewPassword}
            component={SetNewPassword}
          />
          <AuthenticateRoute
            path={RouteKeys.PrivacyPolicy}
            component={PrivacyPolicy}
          />
          <AuthenticateRoute path={RouteKeys.Terms} component={Terms} />
          <AuthenticateRoute path={RouteKeys.JobList} component={JobList} />
          <AuthenticateRoute
            path={RouteKeys.QualityChecklist}
            component={QualityChecklist}
          />
          <AuthenticateRoute
            path={RouteKeys.QualityChecklistSummary}
            component={QualityChecklistSummary}
          />
          <Route  path={`${RouteKeys.WCBoard}`} component={WCBoard} />
          
        </Switch>
      </Suspense>
    );
  }
}

export default Routes;
