import api from '../../../api';
import { SettingsData } from '../../api/models/user';
import { createActionWithPayload, IActionWithPayload } from '../utils';

const SETTINGS_CHANGE_LANGUAGE = 'SETTINGS/CHANGE_LANGUAGE';
const SETTINGS_SET_SETTINGS = 'SETTINGS/SET_SETTINGS';
const SETTINGS_SET_LANGUAGES = 'SETTINGS/SET_LANGUAGES';

export interface ILocale {
  languageID: number;
  languageName: string;
  languageKey: string;
  isRtl: boolean;
}

export class EnglishLocale implements ILocale {
  languageID: number = 1;
  languageName: string = 'English';
  languageKey: string = 'en';
  isRtl: boolean = false;
}
export class ArabicLocale implements ILocale {
  languageID: number = 2;
  languageName: string = 'عربي';
  languageKey: string = 'ar';
  isRtl: boolean = true;
}

export interface SettingsState {
  locale: ILocale;
  settings: SettingsData;
  languages: ILocale[];
}

type ChangeLanguageActionType = IActionWithPayload<
  typeof SETTINGS_CHANGE_LANGUAGE,
  number
>;

type SetSettingsActionType = IActionWithPayload<
  typeof SETTINGS_SET_SETTINGS,
  SettingsData
>;

type SetLanguagesActionType = IActionWithPayload<
  typeof SETTINGS_SET_LANGUAGES,
  ILocale[]
>;

type Actions = ChangeLanguageActionType | SetSettingsActionType | SetLanguagesActionType;

const initialState: SettingsState = {
  locale: new EnglishLocale(),
  settings: null,
  languages: [],
};

export const changeLanguageAction = (languageID: number) => {
  return createActionWithPayload(SETTINGS_CHANGE_LANGUAGE, languageID);
};

export const setSettingsAction = (settings: SettingsData) => {
  return createActionWithPayload(SETTINGS_SET_SETTINGS, settings);
};

export const setLanguagesAction = (languages: ILocale[]) => {
  return createActionWithPayload(SETTINGS_SET_LANGUAGES, languages);
};

// reducer
export const settingsReducer = (
  state: SettingsState = initialState,
  action: Actions
): SettingsState => {
  switch (action.type) {
    case SETTINGS_CHANGE_LANGUAGE:
      let locale: ILocale;
      switch (action.payload) {
        case 1:
          locale = new EnglishLocale();
          break;
        case 2:
          locale = new ArabicLocale();
          break;
        default:
          locale = new EnglishLocale();
      }
      api.setLanguageID(action.payload);
      return {
        ...state,
        locale,
      };
      
    case SETTINGS_SET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };

    case SETTINGS_SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };

    default:
      return state;
  }
};
