import { string } from 'prop-types';
import { Dict } from '../../../models';
import { IAdduserBrandsParams, IAssignedStates } from './model';
import { FilterValueType } from './saved-filters';

export interface User {
  _id?: string;
  name: string;
  email?: string;
  phone?: string;
  userProfilePicture?: string;
  displayArabicName?: string;
}

export interface RequestedBy {
  userID: string;
  userName: string;
  role?: string;
  phone?: string;
}

export interface CartDetails {
  cartID: string;
  orderQty: string;
  orderTotalAmount: string;
}
export interface QuotDetails {
  quotationRequestID: string;
  totalQty: string;
}

export interface TermsResponse {
  terms?: string;
}

export interface MediaFile {
  encoding: string;
  fieldname: string;
  filename: string;
  mimetype: string;
  originalname: string;
  path: string;
  size: number;
  location?: string;
}

export interface DocumentList {
  userID: number;
  fileName: string;
  documentMaster: number;
  documentType: string;
  documentUrl: string;
  createdDate: Date;
  documentUrlFull: string;
  typeName: string;
  documentID: number;
  documentStatus: string;
}

export interface DocumentResponse {
  list: DocumentList[];
  type: { documentTypeID: number; documentTypeName: string }[];
}

export interface UserResponse {
  result: UsersList[];
  totalUsers?: number;
}

export interface UsersList {
  userID: string;
  userFirstName: string;
  userLastName: string;
  userFullName: string;
  userCountryCode: string;
  userPhoneDialCode: string;
  userPhone: string;
  userStatus: string;
  userDesignation: string;
  userEmail: string;
  orderPermission: boolean | any;
  accessToken: string;
}

export default interface Company {
  userID: number;
  billingName?: string;
  tradeLicenseNo?: number;
  trn?: string;
  userEmail?: string;
  userWebsite?: string;
  userPhoneCountryCode?: string;
  userPhoneDialCode?: string;
  userPhone?: string;
  userAddress?: string;
  userCity?: string;
  userZip?: string;
  userCountry?: number;
  countryName?: string;
  stateName?: string;
  userProvince?: string;
  billingPhoneDialCode?: string;
  billingPhoneCountryCode?: string;
  billingPhone?: string;
  companyLogo?: string;
  billingEmail?: string;
  companyName?: string;
  tradeno?: string;
  userAdress1?: string;
}

export interface IPersonalInfo {
  countryName: string;
  languagesKnown: string;
  roleTypeID: string;
  rolesTypeName: string;
  stateID: string;
  stateName: string;
  userAddress: string;
  userAdress2: string;
  userCity: string;
  userCountry: string;
  userDOB: string;
  userDesignation: string;
  userEmail: string;
  userFirstName: string;
  userGender: string;
  userID: string;
  userLastName: string;
  userPhone: string;
  userPhoneDialCode: string;
  userProfilePicture: string;
  userZip: string;
  assignedManagers: IAssignedManagers[];
  statesNames: IAssignedStates[];
  assignedStates: IAssignedStates[];
  arabicName?: string;
}

export interface IUserLog {
  activityID: string;
  userID: string;
  referenceID: string;
  createdDate: string;
  displayText: string;
  activityType: string;
  salesPersonID: string;
}
export interface IUserLogParams {
  userID: string;
  perPage: number;
  page: number;
  userToken?: string;
}

export interface IAssignedManagers {
  id: string;
  name: string;
  role: string;
}
export interface ISalesman {
  salesPersonName: string;
  userID: string;
  firstName: string;
  userEmail?: string;
}
export interface IBrandWithAccountNumber {
  brandID: string;
  accountNumber: string;
  name: string;
}

export interface ICustomer {
  brandWithAccountNumber: IBrandWithAccountNumber[];
  accountCode?: string;
  cartTotalAmount: string;
  customerCode: string;
  companyName?: string;
  creditApplyEditID?: number;
  creditApplyNeeded?: number;
  customerName: string;
  lastOrderDate: string;
  lastOrderValue: string;
  orderTotalQty: string;
  orderTotalAmount: string;
  tradeno?: string;
  trn?: string;
  userCountryCode: string;
  userCity?: string;
  stateID?: string;
  stateName?: string;
  userEmail: string;
  userFirstName?: string;
  userID: string;
  userPhone: string;
  userRegistredDate?: string;
  userLastName?: string;
  userToken: string;
  userStatus: 'Declined' | 'Approved' | 'Requested';
  vatNo: string;
}

export interface IUseBrandDetails {
  userToWarehouseID: string;
  userID: string;
  brandID: string;
  // warehouseID: string;
  // salesPersonID: string;
  // creditLimit: string;
  // accountNumber: string;
  // discountCode: string;
  status: boolean;
  warehouses: IWarehouse[];
  warehouseList: IWarehouseList[];
}
export interface IWarehouse {
  warehouseID: string;
  status: string;
  warehouseName: string;
  creditLimit?: string;
  discountCode?: string;
  salesPersonID: string;
  accountNumber?: string;
}

export interface IWarehouseList {
  warehouseID: string;
  warehouseName: string;
}

export interface IDate {
  startDate: string;
  endDate: string;
}

export interface ICustomerFilterParams {
  filter: Dict<FilterValueType>;
  page: number;
  perPage: number;
  searchKeyword?: string;
  fromDate?: string;
  toDate?: string;
}

export interface ICustomerRequestFilterParams {
  filter: Dict<FilterValueType>;
  page: number;
  perPage: number;
  searchName?: string;
  fromDate?: string;
  toDate?: string;
  status?: UserStatus;
}

export enum UserStatus {
  Requested = 'Requested',
  Approved = 'Approved',
  Declined = 'Declined',
}

export enum BranchStatus {
  Requested = 'Requested',
  Active = 'Active',
  Inactive = 'Inactive',
}

export interface CustomerRequestResponse {
  list: ICustomer[];
  declinedUserCount: string;
  approvedUserCount: string;
  newUserCount: string;
}

export interface IPhoneData {
  countryCode?: string; // shippingPhoneCountryCode
  dialCode?: string; // shippingPhoneDialCode
  entered?: string; // shippingPhone
}
export interface IReason {
  reasonID: string;
  reasons: string;
}

export interface DeclineRequestParam {
  userID: string;
  reasonID: string;
  declineComment: string;
  notifyCustomer: 'Yes' | 'No';
}

export interface IAddApproveParam {
  userID: string;
  brands: IAdduserBrandsParams[];
}
export interface IAddBrands {
  brandID: string;
  branches: IAddBranch[];
  accountCode: string;
  salesPersonID: string;
  creditLimit: string;
  discountCode?: string;
}
export interface IAddBranch {
  warehouseID: string;
  status: string;
}

export interface ICustomerBulkImportResponse {
  companyName: string;
  countryID: string;
  userPhoneDialCode: string;
  userPhone: string;
  userEmail: string;
  tradeno: string;
  trn: string;
  vatNo: string;
  userCity: string;
  stateID: string;
  stateName: String;
  brand: string;
  brandID: string;
  branch: string;
  warehouseID: string;
  accountNumber: string;
  creditLimit: string;
  salesPersonID: string;
  salesPersonName: string;
  branches: ICustoerBranch[];
  address: ICustomerAddress[];
}

export interface ICustoerBranch {
  brandID: string;
  warehouseID: string;
  brandName: string;
  branchName: string;
  salesPersonName: string;
  creditLimit: string;
  accountNumber: string;
  salesPersonID: string;
}

export interface ICustomerAddress {
  shippingAddress1: string;
  shippingCity: string;
  shippingCountryID: string;
  shippingCountry: string;
  shippingState: string;
  shippingStateID: string;
  shippingPhone: string;
  shippingPhoneDialCode: string;
}

export interface IScheduleResponse {
  unscheduledCustomers: IScheduledCustomer[];
  scheduledCustomers: IScheduleItem[];
}
export interface IScheduleItem {
  day: string;
  usersCount: number;
  userList: IScheduledCustomer[];
}

export interface IDayScheduleItem {
  date: string;
  adteCustom: string;
  datedispaly: string;
  edit: string;
  itemIDs: string;
  masterScheduleID: string;
  weekDay: string;
  userList: IScheduledCustomer[];
}

interface Address {
  shippingLname?: string;
  shippingFname?: string;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  phone: string;
  countryName: string;
  stateName: string;
}

export interface IScheduledCustomer {
  userID: string;
  userName: string;
  userAddress: string;
  address?: Address;
  addressID?: string;
  accountCode: string;
  userEmail: string;
  userPhone: string;
  salesperson: string;
  userToken: string;
  scheduledDays: string[];
  totalOrders: string;
  billingName: string;
  customerRequestedDate?: string;
}
export interface IScheduleListParams {
  searchKeyword?: string;
  date?: string;
  day?: string;
  page?: string;
  perPage?: string;
  brandID?: string[];
  salespersonID?: string[];
  fromWeb?: string;
}

export interface IScheduleCustomerListResponse {
  customerlist: IScheduleCustomerlist[];
  scheduleID: string;
  day: string;
}

export interface IScheduleCustomerlist {
  shippingLname?: string;
  shippingFname?: string;
  userPhone: string;
  userID: string;
  addressID?: string;
  userFirstName: string;
  userLastName: string;
  companyCode: string;
  parentID: string;
  userStatus: string;
  billingName: string;
  website_id: string;
  store_name: string;
  website_name: string;
  userAdress1: string;
  webstorename: string;
  store_code: string;
  store_id: string;
  locationName: string;
  salesPerson: string;
  salespersonIDs: string[];
  Sunday: string;
  Monday: string;
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
  scheduled: string;
  shippingAddress1?: string;
  shippingAddress2?: string;
  shippingCity?: string;
  stateName?: string;
  shippingZip?: string;
  countryName?: string;
  shippingPhone?: string;
  userUniqueID?: string;
}
export interface ICustomerDashboard {
  SalesTarget: number;
  Achieved: string;
  nextMonthTarget: string;
  infoMsgShow: boolean;
  msg: string;
  grpah: GraphData[];
  rebatDet: any[]; // Assuming this can be an array of any type
  years: Year[];
  viewOptions: string[];
  creditValueReport: CreditValueReport;
  generalView: GeneralView;
  totalSalePortal?: any;
  totalSaleOffline?: any;
  salePercentage?: any;
}

export interface GraphData {
  name: string;
  SalesTarget: string;
  Achieved: number;
  percentage: number;
}

export interface Year {
  ID: number | string;
  name: string;
}

export interface CreditValueReport {
  creditLimit: string;
  Used: number;
  Balance: string;
}

export interface GeneralView {
  totalCreditLimit: string;
  totalcreditTerm: string;
  totalOverDueAmountZeroToThirtyDays: string;
  totalOverDueAmountThirtyOneToNinetyDays: string;
  totalOverDueAmountNinetyOneToOneTwentyDays: string;
  totalOverDueAmountOneTwentyOneToOneFiftyDays: string;
}

// dashboard values
export interface MonthData {
  name: string;
  SalesTarget: string;
  Achieved: string;
  percentage: number;
}

export interface SalesData {
  currentMonthData: MonthData;
  prevMonthData: MonthData;
  creditLimit: string;
  totalDueAmount: string;
}

export interface SavedSearchDetails {
  searchID: string;
  searchName: string;
}

export interface SavedSearchFolder {
  searchFolderID: string;
  updatedDate: string;
  folderName: string;
  searchDetails: SavedSearchDetails[];
}
export interface NewOtpResponse {
  otpExpireTime: number;
  otpLimitFP: string;
  otpLimitSignup: string;
  otpRequestCountUser: string;
  otpRequestCountUserFP: string;
  userID: number;
}

export interface SettingsData {
  passwordRuleText: string;
  passwordRuleArr: string[];
  otpExpireTime: number;
  timeZoneDiff: string;
}
