import React, {
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ErrorMessage from '../error-message/ErrorMessage';

interface CustomProps {
  title?: string;
  subText?: string;
  alignCenter?: boolean;
  valueOnSubmit?: (data: any) => void;
}

const OTP = (props: CustomProps, ref) => {
  const { handleSubmit, register, errors, setValue, reset, watch } = useForm();
  const { t } = useTranslation();
  const inputRefs: any = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()]; // Create refs for each input

  const handleInputChange = (index: number, value: string) => {
    setValue(`input${index + 1}`, value); // Set value in react-hook-form
    if (value !== '' && index < inputRefs?.length - 1) {
      inputRefs[index + 1]?.current.focus(); // Focus on the next input if not empty
    }
  };

  useImperativeHandle(ref, () => ({
    reset,
  }));
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData('text/plain')
      .slice(0, inputRefs.length);
    for (let i = 0; i < pastedData.length; i++) {
      handleInputChange(i, pastedData[i]);
    }
  };

  useEffect(() => {
    // Watch for changes in the input values
    const values = Object.values(
      watch(['input1', 'input2', 'input3', 'input4', 'input5', 'input6'])
    );
    const filledInputs = values.filter((value: string) => value !== '');

    // Check if the component is still mounted
    if (inputRefs.every((ref) => ref.current)) {
      if (filledInputs.length > 0) {
        // If there are filled inputs, focus on the last filled input
        const lastFilledIndex = values.lastIndexOf(
          filledInputs[filledInputs.length - 1]
        );
        inputRefs[lastFilledIndex + 1]?.current?.focus();
      }
    }
  }, [watch, inputRefs]);

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Backspace' && index > 0 && e.currentTarget.value === '') {
      // If backspace is pressed and the input is empty, focus on the previous input
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <form
      onSubmit={
        props?.valueOnSubmit &&
        handleSubmit((e) => {
          props?.valueOnSubmit(e);
          reset();
        })
      }
    >
      {props.title && (
        <h5 className="font-bold text-[22px] lg:text-2xl mb-2">
          {props.title}
        </h5>
      )}
      {props.subText && (
        <p className="text-muted text-sm lg:text-[15px] mb-5 lg:mb-8">
          {props.subText}
        </p>
      )}
      <div className="pb-6">
        <div className="flex gap-2 items-center justify-center">
          {Array.from({ length: 6 }, (_, index) => (
            <div className="col-span-3" key={index}>
              <input
                maxLength={1}
                name={`input${index + 1}`}
                type="number"
                className="form-control text-xl lg:text-2xl text-center font-bold leading-6 py-2"
                ref={(el) => {
                  inputRefs[index].current = el; // Set ref for each input
                  register(el, { required: true, pattern: /^[0-9]$/ }); // Add pattern validation for single digit
                }}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onPaste={handlePaste}
                onKeyDown={(e) => handleKeyDown(index, e)}
              />
            </div>
          ))}
        </div>
      </div>
      {(errors?.input1 ||
        errors?.input2 ||
        errors?.input3 ||
        errors?.input4) && (
        <ErrorMessage text={t('common.Please Enter the valid OTP')} />
      )}
      <div className="py-3 mb-3">
        <button type="submit" className="btn btn-primary w-full">
          {t('common.Verify')}
        </button>
      </div>
    </form>
  );
};

export default forwardRef(OTP);
