import ApiService from './service';
import * as http from './http';
import {
    ApiResponse,
    IBoardData,
  } from '../models';


export class BoardService extends ApiService {
  static getBoardData = '/api/index/tv/';

  public async getBoardData(payload) {
    const url = `${this.apiDomain}${BoardService.getBoardData}`;
    const response = await http.post<ApiResponse<IBoardData>>(url, payload);
    return response.data;
  }
}
