import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Layout from '../../core/layouts/Layout';
import AuthCanvas from '../../components/auth/AuthCanvas';
import { Input } from '../../components/form-components';
import { useForm, Controller } from 'react-hook-form';
import { useAuth } from '../../core/hooks';
import { useTranslation } from 'react-i18next';
import { RouteKeys } from '../routes/route-keys';
import { toastr } from 'react-redux-toastr';
import { FullPageLoader } from '../../components/loader';
import ErrorMessage from '../../components/error-message/ErrorMessage';
import Header from '../../components/header';

const Password: React.SFC = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { control, errors, handleSubmit } = useForm();
  const location: any = useLocation();
  const storeOperatorID = location?.state?.storeOperatorID;
  const history = useHistory();
  const onSuccess = () => {};
  useEffect(() => {
    if (!storeOperatorID) {
      history?.push(RouteKeys?.Login);
    }
  }, [location]);
  const onPasswordSubmit = (data: any) => {
    auth.submitPassword(
      { password: data?.password, email: storeOperatorID },
      null,
      onSuccess
    );
  };
  useEffect(() => {
    if (auth.loginError) {
      toastr.error('Error', auth.loginError);
    }
  }, [auth.loginError]);
  return (
    <Layout className="bg-slate-50" notLoggedIn>
      {auth?.updating && <FullPageLoader text="Loading..." />}
      <Header notLoggedIn={true} />
      <form
        onSubmit={handleSubmit(onPasswordSubmit)}
        className="container pt-1.5 lg:pt-10 pb-14"
      >
        <AuthCanvas
          head={t('common.Password')}
          subText={t('common.LoginSubtext')}
        >
          <div className="pb-1.5">
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={(props) => {
                return (
                  <Input
                    type="password"
                    inputClassName="form-control"
                    labelClassName="block text-sm mb-1.5"
                    label={t('common.Password')}
                    placeholder={t('common.EnterPassword')}
                    {...props}
                  />
                );
              }}
            />
            {errors?.password && (
              <ErrorMessage text={t('common.Please Enter the Password')} />
            )}
          </div>
          <div className="ltr:text-right rtl:text-left pb-6">
            <Link
              to={'/auth/forgot-password'}
              className="text-sm text-muted font-medium"
            >
              {t('common.ForgotPassword')}
            </Link>
          </div>
          <div>
            <button type="submit" className="btn btn-primary w-full">
              {t('common.Submit')}
            </button>
          </div>
        </AuthCanvas>
      </form>
    </Layout>
  );
};

export default Password;
