import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import useTimer from '../../core/hooks/useTimer';
import { useTranslation } from 'react-i18next';

const Timer = (
  {
    timerKey,
    beforeText,
    time,
    children,
  }: {
    beforeText?: string;
    time: number;
    timerKey: string;
    children: any;
  },
  ref
) => {
  const { minutes, seconds, isExpired, restartTimer } = useTimer(
    time,
    timerKey
  );
  const { t } = useTranslation();
  useImperativeHandle(ref, () => ({
    restartTimer,
  }));

  useEffect(() => {
    restartTimer();
  }, [timerKey]);

  return (
    <>
      {isExpired ? (
        <div>{children}</div>
      ) : (
        <>
          {t(beforeText)} {minutes}:{seconds} {t('common.Minutes')}
        </>
      )}
    </>
  );
};

export default forwardRef(Timer);
