import React, { lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserPermissionTypes, UserType } from '../../core/api/models';
import { useAuth } from '../../core/hooks';

import { RouteKeys } from './route-keys';

interface CustomProps {
  component: any;
  exact?: boolean;
  path?: string;
  needCheckIn?: boolean;
  hasPermissionToView?: boolean;
  requiredPermission?: UserPermissionTypes;
  onlyForSalesperson?: boolean;
}

const AuthenticateRoute: React.FunctionComponent<CustomProps> = (props) => {
  const auth = useAuth();
  const { component, ...rest } = props;

  const { hasRole, isSalesPerson } = useAuth();

  const hasPermissionToView = () => {
    if (props.onlyForSalesperson) {
      return isSalesPerson;
    }
    if (!props.requiredPermission) {
      return true;
    }
    return hasRole(props.requiredPermission);
  };

  return (
    <Route
      {...rest}
      render={(childProps) => {
        if (hasPermissionToView()) {
          if (auth.isAuthenticated) {

            return <props.component {...childProps} />;

          }
          auth.redirectSave('');
          return (
            <Redirect
              to={{
                pathname: RouteKeys.Login,
              }}
              from={childProps.location.pathname}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: RouteKeys.PermissionNeeded,
              }}
              from={childProps.location.pathname}
            />
          );
        }
      }}
    />
  );
};

export default AuthenticateRoute;
